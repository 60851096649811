import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const Box = styled.div`
  border: 2px solid green;
  border-radius: 5px;
  padding: 1rem 3rem;
  margin: 0 1rem 1rem;
`

const Country = styled.h4`
  text-align: center;
`

const Date = styled.p`
  text-align: center;
  font-weight: 700;
  font-size: 1.25em;
`

const EventDescription = styled.p`
  text-align: center;
  font-size: 0.875em;
`

const Button = styled.div`
  background-image: linear-gradient(180deg, #30ab76 0%, #71c552 100%);
  border-radius: 5px;
  margin-top: 1rem;
  opacity: 1;
  &:hover {
    opacity: 0.8;
  }
`

const CommingSoonButton = styled.div`
  background-color: #c6c6c6;
  border-radius: 5px;
  margin-top: 1rem;
  padding: 0.75rem;
  color: #777777;
  font-size: 0.75em;
  text-align: center;
`

const UndefinedDate = styled.p`
  text-align: center;
  font-weight: 700;
  font-size: 1.25em;
  color: #454444;
`

const Flag = styled.img`
  width: 18px;
  height: 12px;
`

const buttonStyle = css`
  color: #ffffff;
  text-decoration: none;
  font-size: 0.75em;
  font-weight: 700;
  text-align: center;
  display: block;
  padding: 0.75rem;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`

type Props = {
  to: string
  country: string
  date?: string
  flag: string
}

const EventBox = ({ to, country, date, flag }: Props) => {
  return (
    <Box>
      <Country>
        <Flag src={`/${flag}-flag.png`} alt={country} /> {country}
      </Country>

      {date ? <Date>{date}</Date> : <UndefinedDate>Pronto...</UndefinedDate>}

      <EventDescription>EVENTO GRATUITO</EventDescription>

      {date ? (
        <Button>
          <Link to={to} css={buttonStyle}>
            Quiero saber más
          </Link>
        </Button>
      ) : (
        <CommingSoonButton>Pronto</CommingSoonButton>
      )}
    </Box>
  )
}
export default EventBox
