import React from "react"
import styled from "@emotion/styled"

import SEO from "../components/seo"
import Layout from "../components/layout"
import EventBox from "../components/event-box"
import Hero from "../components/hero"

const Message = styled.div`
  padding: 0 1rem;
`

const Title = styled.h2`
  text-align: center;
  font-size: 2.4375em;
  font-weight: bold;
`

const Description = styled.p`
  text-align: center;
`

const EventContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  @media (max-width: 420px) {
    flex-direction: column;
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="¡UPS! No hay nada por acá" />
    <Hero />

    <Message>
      <Title>¡UPS! No hay nada por acá</Title>
      <Description>
        Selecciona un país de abajo y continúa navegando
      </Description>
    </Message>

    <EventContainer>
      <EventBox to="/peru/" country="PERÚ" date="05/02/2020" flag="peru" />
      <EventBox
        to="/uruguay/"
        country="URUGUAY"
        date="05/02/2020"
        flag="uruguay"
      />
      <EventBox to="/" country="CHILE" flag="chile" />
    </EventContainer>
  </Layout>
)

export default NotFoundPage
